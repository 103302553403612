import VueRouter from "vue-router";
import "@/routes";
import Vue from "vue";
import Home from "@/views/Home";
import Live from "@/views/Live";
import { auth } from "@/firebase";

Vue.use(VueRouter);

/**
 * Routes, using chunk splitting for better performance
 */
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "",
      requiresAuth: false,
    },
  },
  {
    path: "/live",
    name: "Live",
    component: Live,
    meta: {
      title: "Live",
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login"),
    meta: {
      title: "Kirjaudu",
      hideForAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

/*
 * Setting up a login handler for firebase, making sure that the user is logged in.
 */
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const hideForAuth = to.matched.some((x) => x.meta.hideForAuth);

  if (requiresAuth && !auth.currentUser) {
    next("/login");
  } else {
  if (hideForAuth && auth.currentUser) next("/live");
    next();
  }
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    if (to.meta.title) document.title = `Sakara Live - ${to.meta.title}`;
  });
});

export default router;
