<template>
  <div>
    <div class="vimeo-wrapper">
      <iframe
          src="https://player.vimeo.com/video/510692332?background=1&autoplay=1&loop=1&byline=0&title=0"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
      >
      </iframe>
    </div>

    <div class="h-screen flex flex-col justify-start items-center pb-5">
      <div class="countdown text-center">
        <!-- <flip-countdown deadline="2021-03-05 20:00:00"></flip-countdown> -->
        <!--
        <a v-if="!isUser" href="https://www.levykauppax.fi/merch/stam1na/novus_ordo_mundi_live/#725636" target="_blank">
          <button class="btn-yellow py-2 px-4 mt-4 rounded">Osta lippu</button>
        </a>
        <router-link to="/live" v-if="eventInfo != null && eventInfo.loginEnabled">
          <button v-if="isUser" class="btn-yellow btn-green py-2 px-4 mt-4 ml-4 rounded">
            <span>Jatka katselua</span>
          </button>
          <button v-else class="btn-yellow py-2 px-4 mt-4 ml-4 rounded">
            <span>Aloita katselu</span>
          </button>
        </router-link>
         -->
      </div>
    </div>
  </div>
</template>

<script>
import {auth, db} from "@/firebase";
// import FlipCountdown from 'vue2-flip-countdown'

export default {
  name: "Home",
  // components: {FlipCountdown},
  data() {
    return {
      cssProps: {
        // backgroundImage: `url(${require("@/assets/mantti.png")})`,
      },
      eventInfo: null
    };
  },
  firestore: {
    eventInfo: db.collection('events').doc('Q9PvNZz5noKmtoVqUm5k')
  },
  computed: {
    isUser: function () {
      if (auth.currentUser) {
        // console.debug( auth.currentUser )
        return true
      }
      return false
    }
  },
  mounted() {
    // console.debug(this.$Tawk)
  }
};
</script>

<style lang="scss">
.content {
  margin-top: 3em;
  color: white;
  font-weight: bold;

  h2 {
    margin: 1em 0 0 0;
    font-size: 1.6em;
  }
}


</style>
