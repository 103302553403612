import Vue from "vue";
import App from "./App.vue";
import router from "@/routes";
import { auth } from "@/firebase";
import { firestorePlugin } from 'vuefire'
import Tawk from 'vue-tawk'
import "@/assets/css/style.css";

Vue.config.productionTip = false;

Vue.use(firestorePlugin)

Vue.use(Tawk, {
  tawkSrc: 'https://embed.tawk.to/60336c2d9c4f165d47c5b57c/1ev4dt4v2'
})

// Only render app when firebase auth is initialized, reusing the app.
let app;
auth.onAuthStateChanged(() => {
  if (!app)
    app = new Vue({
      router,
      render: (h) => h(App),
    }).$mount("#app");
});
